var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "company-users-view"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-user-gear",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("List of roles that belong to this company.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('router-link', {
          staticClass: "btn btn-primary",
          attrs: {
            "to": {
              path: `/company/roles/create`
            },
            "data-cy": "add-role-btn"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-1"
        }), _vm._v(" Add Role ")])];
      },
      proxy: true
    }])
  }, [[_vm._v("Roles")]], 2), _c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.roles,
      "total": _vm.rolesTotal,
      "loading": _vm.loadingAction.listRoles,
      "columns": _vm.columns,
      "page-size": 20,
      "fetch-items": _vm.refresh,
      "default-sort-key": "name",
      "default-sort-direction": 1
    },
    scopedSlots: _vm._u([{
      key: "systemOnly",
      fn: function (_ref) {
        var data = _ref.data;
        return [data.systemOnly ? _c('span', {
          staticClass: "badge badge-info"
        }, [_vm._v("System")]) : _c('span')];
      }
    }, {
      key: "actions",
      fn: function (_ref2) {
        var data = _ref2.data;
        return [_c('div', {
          staticClass: "d-flex"
        }, [!data.systemOnly ? _c('router-link', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "to": {
              name: 'company-roles-edit',
              params: {
                roleId: data._id
              }
            }
          }
        }, [_vm._v("Manage")]) : _vm._e(), !data.systemOnly ? _c('button', {
          staticClass: "btn btn-sm btn-danger ml-2",
          on: {
            "click": function ($event) {
              _vm.modals.remove = data;
            }
          }
        }, [_vm._v("Remove")]) : _vm._e()], 1)];
      }
    }])
  }), _c('ConfirmModal', {
    attrs: {
      "id": "remove",
      "open": !!_vm.modals.remove,
      "title": "Remove Role",
      "text": `Please confirm you would like to remove role:<br/><br/><strong>${_vm.modals.remove.name}</strong>`
    },
    on: {
      "close": function ($event) {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemoveRole
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }